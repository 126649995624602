import styled from 'styled-components'
import React from 'react'

export const BGstar = () => {
  const BGSVGContainer = styled.div`
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100vw !important;
    display: flex;
    justify-content: center;
    width: 100vw;
  `
  return (
    <BGSVGContainer>
      <svg xmlns="http://www.w3.org/2000/svg" width="936" height="760" viewBox="0 0 936 760" fill="none">
        <g opacity="0.52" filter="url(#filter0_f_724_10624)">
          <path
            d="M876 -116L748.558 90.1619C672.094 213.858 672.094 370.142 748.558 493.838L876 700L669.838 572.559C546.142 496.094 389.858 496.094 266.162 572.559L59.9998 700L187.441 493.838C263.906 370.142 263.905 213.858 187.441 90.1619L59.9998 -116L266.162 11.4415C389.858 87.9057 546.142 87.9056 669.838 11.4415L876 -116Z"
            fill="url(#paint0_linear_724_10624)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_724_10624"
            x="-0.000297546"
            y="-176"
            width="936"
            height="936"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="30" result="effect1_foregroundBlur_724_10624" />
          </filter>
          <linearGradient
            id="paint0_linear_724_10624"
            x1="468"
            y1="-116"
            x2="468"
            y2="700"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#22B3EC" />
            <stop offset="1" stopColor="#8E53E6" />
          </linearGradient>
        </defs>
      </svg>
    </BGSVGContainer>
  )
}
