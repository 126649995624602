import React, { useMemo } from 'react'
import styled from 'styled-components'
import { isAddress } from 'utils'
import Logo from '../Logo'
import { useCombinedActiveList } from 'state/lists/hooks'
import useHttpLocations from 'hooks/useHttpLocations'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { LineaGoerliNetworkInfo, OptimismNetworkInfo, ZKatanaNetworkInfo } from 'constants/networks'
import EthereumLogo from '../../assets/images/ethereum-logo.png'
import DAILogo from '../../assets/images/dai-logo.png'
import USDTLogo from '../../assets/images/usdt-logo.png'
import USDCLogo from '../../assets/images/usdc-logo.png'
import { ChainId } from '@uniswap/sdk-core'

export function chainIdToNetworkName(networkId: ChainId) {
  switch (networkId) {
    case ChainId.MAINNET:
      return 'ethereum'
    case ChainId.ARBITRUM_ONE:
      return 'arbitrum'
    case ChainId.OPTIMISM:
      return 'optimism'
    case ChainId.POLYGON:
      return 'polygon'
    case ChainId.BNB:
      return 'smartchain'
    case ChainId.BASE:
      return 'base'
    case ChainId.LINEA_GOERLI:
      return 'lineaGoerli'
    case ChainId.ZKATANA:
      return 'zKatana'
    default:
      return 'ethereum'
  }
}

const getTokenLogoURL = ({ address, chainId }: { address: string; chainId: ChainId }) => {
  return `https://raw.githubusercontent.com/uniswap/assets/master/blockchains/${chainIdToNetworkName(
    chainId
  )}/assets/${address}/logo.png`
}

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.text4};
`

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

export default function CurrencyLogo({
  address,
  size = '24px',
  style,
  ...rest
}: {
  address?: string
  size?: string
  style?: React.CSSProperties
}) {
  // useOptimismList()
  const optimismList = useCombinedActiveList()?.[10]
  const arbitrumList = useCombinedActiveList()?.[42161]
  const polygon = useCombinedActiveList()?.[137]
  const celo = useCombinedActiveList()?.[42220]
  const bnbList = useCombinedActiveList()?.[ChainId.BNB]
  const baseList = useCombinedActiveList()?.[ChainId.BASE]

  const [activeNetwork] = useActiveNetworkVersion()

  const checkSummed = isAddress(address)

  const optimismURI = useMemo(() => {
    if (checkSummed && optimismList?.[checkSummed]) {
      return optimismList?.[checkSummed].token.logoURI
    }
    return undefined
  }, [checkSummed, optimismList])
  const uriLocationsOptimism = useHttpLocations(optimismURI)

  const arbitrumURI = useMemo(() => {
    if (checkSummed && arbitrumList?.[checkSummed]) {
      return arbitrumList?.[checkSummed].token.logoURI
    }
    return undefined
  }, [checkSummed, arbitrumList])
  const uriLocationsArbitrum = useHttpLocations(arbitrumURI)

  const BNBURI = useMemo(() => {
    if (checkSummed && bnbList?.[checkSummed]) {
      return bnbList?.[checkSummed].token.logoURI
    }
    return undefined
  }, [checkSummed, bnbList])
  const uriLocationsBNB = useHttpLocations(BNBURI)

  const BaseURI = useMemo(() => {
    if (checkSummed && baseList?.[checkSummed]) {
      return baseList?.[checkSummed].token.logoURI
    }
    return undefined
  }, [checkSummed, baseList])
  const uriLocationsBase = useHttpLocations(BaseURI)

  const polygonURI = useMemo(() => {
    if (checkSummed && polygon?.[checkSummed]) {
      return polygon?.[checkSummed].token.logoURI
    }
    return undefined
  }, [checkSummed, polygon])
  const uriLocationsPolygon = useHttpLocations(polygonURI)

  const celoURI = useMemo(() => {
    if (checkSummed && celo?.[checkSummed]) {
      return celo?.[checkSummed].token.logoURI
    }
    return undefined
  }, [checkSummed, celo])
  const uriLocationsCelo = useHttpLocations(celoURI)

  //temp until token logo issue merged
  const tempSources: { [address: string]: string } = useMemo(() => {
    return {
      ['0x4dd28568d05f09b02220b09c2cb307bfd837cb95']:
        'https://assets.coingecko.com/coins/images/18143/thumb/wCPb0b88_400x400.png?1630667954',
    }
  }, [])

  const srcs: string[] = useMemo(() => {
    const checkSummed = isAddress(address)

    if (checkSummed && address) {
      const override = tempSources[address]
      return [
        getTokenLogoURL({ address: checkSummed, chainId: activeNetwork.chainId }),
        ...uriLocationsOptimism,
        ...uriLocationsArbitrum,
        ...uriLocationsPolygon,
        ...uriLocationsCelo,
        ...uriLocationsBNB,
        ...uriLocationsBase,
        override,
      ]
    }
    return []
  }, [
    address,
    tempSources,
    activeNetwork.chainId,
    uriLocationsOptimism,
    uriLocationsArbitrum,
    uriLocationsPolygon,
    uriLocationsCelo,
    uriLocationsBNB,
    uriLocationsBase,
  ])

  if (activeNetwork === OptimismNetworkInfo && address === '0x4200000000000000000000000000000000000006') {
    return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} {...rest} />
  }

  // linea goerli USDT
  if (activeNetwork === LineaGoerliNetworkInfo && address === '0xecf4f13b8a0e2b9a82172d4456d2ade209830470') {
    return <StyledEthereumLogo src={USDTLogo} size={size} style={style} {...rest} />
  }

  // ZKatana USDT
  if (
    activeNetwork === ZKatanaNetworkInfo &&
    (address === '0x0a9b934be79a8f978e8c01f001c7c5635d364442' ||
      address === '0x078904a7a7ee26efd0708269c8bc07d88f20e3d5')
  ) {
    return <StyledEthereumLogo src={USDTLogo} size={size} style={style} {...rest} />
  }

  // DAI linea goerli
  if (activeNetwork === LineaGoerliNetworkInfo && address === '0xf4fa92d67d4be549efd3b2251f022971dd2aa56f') {
    return <StyledEthereumLogo src={DAILogo} size={size} style={style} {...rest} />
  }

  // DAI ZKatana
  if (
    activeNetwork === ZKatanaNetworkInfo &&
    (address === '0xc121c0f2104cb98ef5de1135e757d9dae546136a' ||
      address === '0x750e4a7edebfb92ae43cf154d92e28f9f42b218a')
  ) {
    return <StyledEthereumLogo src={DAILogo} size={size} style={style} {...rest} />
  }

  // USDC linea goerli
  if (activeNetwork === LineaGoerliNetworkInfo && address === '0xe69f9284babaec47d8a96d0d44daed0f8f71ac9f') {
    return <StyledEthereumLogo src={USDCLogo} size={size} style={style} {...rest} />
  }

  // ZKatana USDC
  if (activeNetwork === ZKatanaNetworkInfo && address === '0xe245c9f377876222e42bd3f1936b86f2c97d2c9d') {
    return <StyledEthereumLogo src={USDCLogo} size={size} style={style} {...rest} />
  }

  // zkEVM ASTAR
  if (
    activeNetwork === ZKatanaNetworkInfo &&
    address === '0xdf41220C7e322bFEF933D85D01821ad277f90172'.toLocaleLowerCase()
  ) {
    return (
      <StyledEthereumLogo
        src={'https://assets.coingecko.com/coins/images/22617/standard/astr.png'}
        size={size}
        style={style}
        {...rest}
      />
    )
  }

  // zkEVM DOT
  if (
    activeNetwork === ZKatanaNetworkInfo &&
    address === '0x7Cb5d4D178d93D59ea0592abF139459957898a59'.toLocaleLowerCase()
  ) {
    return (
      <StyledEthereumLogo
        src={'https://assets.coingecko.com/coins/images/12171/standard/polkadot.png'}
        size={size}
        style={style}
        {...rest}
      />
    )
  }

  // zkEVM WBTC
  if (
    activeNetwork === ZKatanaNetworkInfo &&
    address === '0xEA034fb02eB1808C2cc3adbC15f447B93CbE08e1'.toLocaleLowerCase()
  ) {
    return (
      <StyledEthereumLogo
        src={
          'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png'
        }
        size={size}
        style={style}
        {...rest}
      />
    )
  }

  // zkEVM MATIC
  if (
    activeNetwork === ZKatanaNetworkInfo &&
    address === '0xa2036f0538221a77A3937F1379699f44945018d0'.toLocaleLowerCase()
  ) {
    return (
      <StyledEthereumLogo
        src={'https://assets.coingecko.com/coins/images/4713/standard/polygon.png'}
        size={size}
        style={style}
        {...rest}
      />
    )
  }

  // zkEVM wstETH
  if (
    activeNetwork === ZKatanaNetworkInfo &&
    address === '0x5D8cfF95D7A57c0BF50B30b43c7CC0D52825D4a9'.toLocaleLowerCase()
  ) {
    return (
      <StyledEthereumLogo
        src={'https://assets.coingecko.com/coins/images/32890/standard/WSTETH.png'}
        size={size}
        style={style}
        {...rest}
      />
    )
  }

  // zkEVM USDC
  if (
    activeNetwork === ZKatanaNetworkInfo &&
    address === '0xA8CE8aee21bC2A48a5EF670afCc9274C7bbbC035'.toLocaleLowerCase()
  ) {
    return <StyledEthereumLogo src={USDCLogo} size={size} style={style} {...rest} />
  }

  // zkEVM USDT
  if (
    activeNetwork === ZKatanaNetworkInfo &&
    address === '0x1E4a5963aBFD975d8c9021ce480b42188849D41d'.toLocaleLowerCase()
  ) {
    return <StyledEthereumLogo src={USDTLogo} size={size} style={style} {...rest} />
  }

  // zkEMV WETH
  if (
    activeNetwork === ZKatanaNetworkInfo &&
    address?.toLocaleLowerCase() === '0xE9CC37904875B459Fa5D0FE37680d36F1ED55e38'.toLocaleLowerCase()
  ) {
    return (
      <StyledEthereumLogo
        src={
          'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png'
        }
        size={size}
        style={style}
        {...rest}
      />
    )
  }

  // ZKatana ETH
  if (
    activeNetwork === ZKatanaNetworkInfo &&
    (address === '0xd2480162aa7f02ead7bf4c127465446150d58452' ||
      address === '0x6c87a8c7bff7c33b3e6ba178583f01178d8bbb84')
  ) {
    return (
      <StyledEthereumLogo
        src={
          'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png'
        }
        size={size}
        style={style}
        {...rest}
      />
    )
  }

  // Linea ETH
  if (activeNetwork === LineaGoerliNetworkInfo && address === '0x2c1b868d6596a18e32e61b901e4060c872647b6c') {
    return (
      <StyledEthereumLogo
        src={
          'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png'
        }
        size={size}
        style={style}
        {...rest}
      />
    )
  }

  return <StyledLogo size={size} srcs={srcs} alt={'token logo'} style={style} {...rest} />
}
